
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































/* stylelint-disable declaration-no-important */
::v-deep {
  section:not(section.hero),
  .duolist-card {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 4em !important;
    padding-bottom: 4em !important;

    @include mq(l) {
      padding-top: 7em !important;
      padding-bottom: 7em !important;
    }
  }

  .services-list__inner {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .tv-offer-switch__item {
    height: inherit;
  }

  .tv-offer-switch__item__picture-outer {
    aspect-ratio: 1 / 1;
  }

  .faq-item {
    border: none !important;

    .faq-item__excerpt {
      @include mq($until: l) {
        padding: 0 $spacing;
      }
    }
  }

  .tv-offer-switch__title {
    max-width: inherit;
  }

  .tv-offer-switch__items .flickity-viewport {
    @include mq($until: l) {
      margin-bottom: 0;
    }
  }

  .tv-offer-switch-inner {
    @include mq(l) {
      margin-bottom: $spacing * 4;
    }
  }

  .tv-offer-switch__subtitle {
    &,
    p {
      margin-top: 0;
    }

    @include mq(l) {
      margin-bottom: 0;
    }
  }

  .tv-offer-switch .btn {
    @include mq($until: l) {
      margin-top: 2em;
    }
  }
}

.giga-promo__faq {
  background-color: transparent;
}

.is-lightest-gray {
  background-color: $c-white-dark;
}

.giga-promo {
  min-height: 100vh;
}

.giga-promo__hero {
  display: grid;
  place-content: center;
  min-height: 40vh;

  h1 {
    @extend %fw-normal;
  }

  b {
    @extend %fw-bold;
  }
}

.giga-promo__offer {
  ::v-deep .tv-offer-switch__subtitle {
    order: -1;
  }
}

.giga-promo__know {
  ::v-deep .did-you-know__intro__title {
    @extend %fw-bold;

    font-size: 48px;
  }
}

.section-hero + .giga-promo__services {
  @include mq($until: l) {
    padding-top: $spacing !important;
  }
}

.giga-promo__duo-list-card + .giga-promo__faq {
  @include mq($until: l) {
    padding-top: $spacing !important;
  }
}
