
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





































































































































.section-hero {
  position: relative;
  overflow: hidden;
  margin-top: $spacing * 5;

  @include mq(m) {
    min-height: 40rem;
  }

  @include mq(l) {
    min-height: 55rem;
    margin-top: $spacing * 6;
  }

  @include mq(xxl) {
    min-height: 70rem;
  }
}

.section-hero__inner {
  @include mq(m) {
    padding-bottom: get-spacing(xl, m);
  }

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $spacing * 2;
    padding-right: 0;
  }

  @include mq(xl) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.section-hero-promo {
  position: relative;
  width: fit-content;
  margin-top: 67px;
  padding: 22px;
  border: 1px solid $c-orange;
  border-radius: 4px;

  span {
    position: absolute;
    top: -11px;
    left: 22px;
  }

  p {
    margin: 0;
  }
}

.btn--no-icon {
  margin-top: 40px;
}

.section-hero__visuals {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1/1;
  margin-top: 7rem;
  transform: scale(1.2);

  @include mq(s) {
    margin-top: 0;
    transform: scale(1);
  }

  .speedlines {
    @include mq($until: l) {
      width: 100%;
    }
  }

  @include mq(m) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
    height: inherit;
    padding: 0;
  }

  @include mq(xl) {
    right: 0;
    width: col(7);
  }

  @include mq(xxl) {
    right: 0;
    aspect-ratio: 1/1;
  }
}

.section-hero__visuals__picture {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  text-align: center;
}

.section-hero__visuals__speedlines {
  z-index: 1;
  left: 0;
  width: 100%;
  transform: scale(1.5);
}

.section-hero__picture {
  margin: 0 auto;

  @include mq(m) {
    position: absolute;
    z-index: -1;
    top: 0;
    right: col(-1);
    width: 60%;
    height: 100%;
  }
}

.section-hero__content {
  @include mq(m) {
    width: col(5.5);
    padding: $spacing * 3 0 0;
  }

  @include mq(l) {
    padding: 0;
  }

  @include mq(xl) {
    width: col(5);
  }
}

.section-hero__content__title {
  margin: 0 0 $spacing;
  font-size: 48px;

  @include mq(l) {
    font-size: 70px;
  }
}

.section-hero__content__text {
  margin-bottom: $spacing * 1.5;

  @include mq($until: m) {
    font-size: 1.5rem;
  }

  @include mq(m) {
    margin-bottom: $spacing * 3;
  }

  @include mq(xl) {
    margin-bottom: $spacing * 2.7;
  }
}

.section-hero__content__icon {
  max-width: 14rem;
  margin-bottom: $spacing * 2.4;
}
